import React from 'react';
import './Legal.css';

const Legal = () => {

  const handlePageClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="legal">
      <h2>Legal</h2>
      <ul>
        <li onClick={() => handlePageClick('https://unstag.in/unstag/app/terms-condition.html')}>Terms & Conditions</li>
        <li>Data Safety</li>
        <li onClick={() => handlePageClick('https://unstag.in/unstag/app/privacy-policy.html')}>Privacy Policy</li>
      </ul>
    </div>
  );
};

export default Legal;
