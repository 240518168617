// src/components/Screens.js
import React from 'react';
import Slider from 'react-slick';
import screen1 from '../assets/Screen/1_Splash Screen-portrait.png';
import screen2 from '../assets/Screen/2_Onboarding-portrait.png';
import screen3 from '../assets/Screen/3_College-portrait.png';
import screen4 from '../assets/Screen/4_Gender-portrait.png';
import screen5 from '../assets/Screen/5_final Profile-portrait.png';
import screen6 from '../assets/Screen/6_Home Screen-portrait.png';
import screen7 from '../assets/Screen/7_Profile Screen-portrait.png';
import screen8 from '../assets/Screen/8_Write post-portrait empty.png';
import screen9 from '../assets/Screen/9_Write post-1-portrait.png';
import screen10 from '../assets/Screen/10_Write post-portrait.png';
import screen11 from '../assets/Screen/11_Comment Screen-portrait.png';
import screen12 from '../assets/Screen/12_Update Screen-portrait.png';
import './Screens.css';

const Screens = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    arrows: false, // Remove next and previous arrows
    autoplaySpeed: 2000, // Adjust the speed as needed (2000ms = 2s)
  };

  const screens = [
    { id: 1, src: screen1 },
    { id: 2, src: screen2 },
    { id: 3, src: screen3 },
    { id: 4, src: screen4 },
    { id: 5, src: screen5 },
    { id: 6, src: screen6 },
    { id: 7, src: screen7 },
    { id: 8, src: screen8 },
    { id: 9, src: screen9 },
    { id: 10, src: screen10 },
    { id: 11, src: screen11 },
    { id: 12, src: screen12 }
  ];

  return (
    <div className="screens-container">
      <Slider {...settings}>
        {screens.map((screen) => (
          <div key={screen.id}>
            <img src={screen.src} alt={`Screen ${screen.id}`} className="screen-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Screens;
