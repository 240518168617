import React, { useState }  from 'react';
import LogoName from '../assets/Logo Name/LogoName.svg'
import logo from '../assets/Logo/logo.svg'
import './Navbar.css';

  
const Navbar = () => {
  const handleDownloadClick = () => {
    window.location.href = 'https://your-download-page.com';
  };

    const [isMobile, setIsMobile] = useState(false);

    const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
    };  
 

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt='logo'></img>
        <img className="logoN" src={LogoName} alt='Unstag'></img>
      </div>
      <div className={`nav-links ${isMobile ? 'mobile' : ''}`}>
        <a href="#products">Our Products</a>
        <a href="#data-safety">Data Safety</a>
        <button onClick={handleDownloadClick} className="download-button">Download</button>
      </div>
      <div className="menu" onClick={toggleMobileMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      
    </nav>
  );
};


export default Navbar;
