// src/components/LandingPage.js
import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Carousel from './Carousel';
import Screens from './Screens';
import Footer from './Footer';
import googlePlayStoreBadge1 from '../assets/Google Play Badge/googlePlayStoreBadge1.svg';
import './LandingPage.css';

const LandingPage = () => {

  const [scrollDirection, setScrollDirection] = useState('slideUp');

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current) {
      setScrollDirection('slideUp');
    } else if (currentScrollY < lastScrollY.current) {
      setScrollDirection('slideDown');
    }
    lastScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const lastScrollY = React.useRef(window.scrollY);


  const handleGooglePlayClick = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=yourapp';
  };

  return (
    <>
    <div>
    <Navbar />
    <div className="landing-page">
      <header className="header">
        <h1 className={`heading1 animated ${scrollDirection}`}>Unleash your thoughts, </h1>
        <h1 className={`heading2 animated ${scrollDirection} delay-1s`}>stay unseen.</h1>
        <h3 className={`heading3 animated ${scrollDirection} delay-1s`}>Get the app</h3>
        <img src={googlePlayStoreBadge1} onClick={handleGooglePlayClick} alt="Download" className="googlePlay-button" />
      </header>

      <div className='header2'>
        <div className='card'>
        <h2 className={`title animated ${scrollDirection} delay-1s`}>Stay Safe !</h2>
        <p className={`description animated ${scrollDirection} delay-2s`}>Don't be too quick to give out all information.</p>
        </div>
        <div className='card'>
          <h2 className={`title animated ${scrollDirection} delay-1s`}>Play it cool.</h2>
          <p className={`description animated ${scrollDirection} delay-2s`}>Respect others and treat them as you would like to be treated.</p>
        </div>
      </div>

      <section className="screens-section">
        <Screens />
      </section>

      <div className="its-all-about">
        <p className={`animated ${scrollDirection}`}>its all about ...</p>
      </div>

      <section className="carousel-section">
        <Carousel />
      </section>

      <section className="footer-section">
        <Footer />
      </section>

    </div>
    </div>
    </>
  );
};

export default LandingPage;
