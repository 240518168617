/*import React from 'react';
import Navbar from './components/Navbar';
import Carousel from './components/Carousel';
import About from './components/About';
import Products from './components/Products';
import Legal from './components/Legal';
import Contact from './components/Contact';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="container">
        <h1>Unleash your thoughts, stay unseen.</h1>
        <p>Get the app!</p>
        <div>
          <button className="download-button">Google Play</button>
        </div>
        <Carousel />
        <About />
        <Products />
        <Legal />
        <Contact />
      </div>
    </div>
  );
};

export default App;*/

import React from 'react';
import LandingPage from './components/LandingPage';
import './App.css';

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;

